/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react

* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)



=========================================================



*/
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Row,
  Col,
  Spinner,
  Progress,
} from "reactstrap";
import { triggerUpdate } from "services";
let id;
function useFakeInterval(callback, waitingTime) {
  const [cycle, setCycle] = useState(0);
  const [startTick, setTick] = useState(false);
  useEffect(() => {
    if (!startTick) {
      setCycle(0);
      id && clearTimeout(id);
      return;
    }
    function tick() {
      callback(cycle);
      setCycle(cycle + 1);
    }
    id = setTimeout(tick, 1000);
    return () => clearTimeout(id);
  }, [cycle, setCycle, waitingTime, callback, startTick]);
  return setTick;
}
export function JuwelSpotlight() {
  const [progress, setProgress] = useState(0);

  const setTick = useFakeInterval((cycle) => {
    // This will fill the loading bar and gets slower to the end (without ever finishing)
    const val = -1400 / (cycle + 10) + 108;
    setProgress(val && val > 0 ? val : 1);
  }, 140000);
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await triggerUpdate();

      setTick(true);
    } catch (error) {
      alert("Error" + JSON.stringify(error));
    }
  };
  if (progress >= 100) {
    setTick(false);
    setProgress(0);
  }
  return (
    <>
      <div className="content">
        <Row>
          <Col md="8">
            <Card>
              <CardHeader>
                <h5 className="title">Spotlight</h5>
              </CardHeader>
              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <CardFooter>
                    <div style={{ height: 110 }}>
                      <h5>
                        After clicking the button, please be patient as the
                        upload process may take up to 3 minutes to complete.
                      </h5>
                      {!!progress && (
                        <>
                          <h5>UPLOADING...</h5>
                          <Progress value={progress} />
                        </>
                      )}
                    </div>

                    <Button
                      className="btn-fill"
                      color="success"
                      disabled={progress}
                      type="submit"
                    >
                      Update
                      <Spinner color="success">Loading...</Spinner>
                    </Button>
                  </CardFooter>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
