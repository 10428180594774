import React, { createContext, useContext, useState } from "react";

// Create a context for auth
const AuthContext = createContext(null);

const JuwelUserName = process.env.REACT_APP_JUWEL_USERNAME;
const JuwelPassword = process.env.REACT_APP_JUWEL_PASSWORD;
const KlyqaUserName = process.env.REACT_APP_KLYQA_USERNAME;
const KlyqaPassword = process.env.REACT_APP_KLYQA_PASSWORD;

// Provide the AuthContext to the application
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // Mock login function
  const login = (username, password) => {
    return new Promise((resolve) => {
      // ... existing login logic
      if (username === JuwelUserName && password === JuwelPassword) {
        setUser({ username, role: "juwel" });
        resolve(true);
      } else if (username === KlyqaUserName && password === KlyqaPassword) {
        setUser({ username, role: "klyqa" });
        resolve(true);
      } else {
        resolve(false);
      }
    });
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        username,
        setUsername,
        password,
        setPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Hook to use auth context
export const useAuth = () => {
  return useContext(AuthContext);
};
