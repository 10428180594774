import axios from "axios";
const { uuidv4, ApiEndpoint, createHeaders } = require("./utils");

export async function profilelogin(username, password) {
  const requestId = uuidv4();

  const response = await axios({
    method: "POST",
    baseURL: ApiEndpoint.test,
    url: "/auth/login",
    data: {
      email: username.trim(),
      password: password,
      environmentName: "Juwel",
    },
    timeout: 10000,
    headers: createHeaders({ requestId }),
  });

  return response?.data;
}

const JUWEL_TRIGGER_BASE_URL = "https://elbstack-git.de/api/v4/projects/130";
const JUWEL_TRIGGER_BRANCH = "main";

export async function triggerUpdate() {
  let config = {
    method: "POST",
    baseURL: JUWEL_TRIGGER_BASE_URL,
    url: `/ref/${JUWEL_TRIGGER_BRANCH}/trigger/pipeline?token=glptt-7cb88540740a07bc4581a352320646ac1d747716`,
    headers: {},
  };

  axios
    .request(config)
    .then(() => {
      console.log("success");
    })
    .catch((error) => {
      console.log(error);
    });
}
