/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react

* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)



=========================================================



*/
import JuwelDashboard from "views/JuwelDashboard.js";
import KlyqaDashboard from "views/KlyqaDashboard.js";
import Icons from "views/Icons.js";
import Notifications from "views/Notifications.js";
import TableList from "views/TableList.js";
import JuwelUserProfile from "views/JuwelUserProfile.js";
import KlyqaUserProfile from "views/KlyqaUserProfile.js";
import { JuwelSpotlight } from "views/JuwelSpotlight";

var routes = [
  /*   {
    path: "/dashboard",
    name: "Juwel Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <JuwelDashboard />,
    layout: "/juwel",
  }, */
  {
    path: "/dashboard",
    name: "Klyqa Dashboard",
    icon: "tim-icons icon-chart-pie-36",
    component: <KlyqaDashboard />,
    layout: "/klyqa",
  },
  /*   {
    path: "/notifications",
    name: "Status of Juwel Sytems",
    icon: "tim-icons icon-sound-wave",
    component: <Notifications />,
    layout: "/juwel",
  }, */
  {
    path: "/notifications",
    name: "Status of Kyyqa Sytems",
    icon: "tim-icons icon-sound-wave",
    component: <Notifications />,
    layout: "/klyqa",
  },

  /*   {
    path: "/user-profile",
    name: "Juwel User Profile",
    icon: "tim-icons icon-app",
    component: <JuwelUserProfile />,
    layout: "/juwel",
  }, */
  {
    path: "/spotlight",
    name: "Spotlight",
    icon: "fa-solid fa-mobile-screen",
    component: <JuwelSpotlight />,
    layout: "/juwel",
  },

  {
    path: "/user-profile",
    name: "Klyqa User Profile",
    icon: "tim-icons icon-single-02",
    component: <KlyqaUserProfile />,
    layout: "/klyqa",
  },
];
export default routes;
