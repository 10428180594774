/*!

=========================================================
* Black Dashboard React v1.2.2
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react

* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)



=========================================================



*/

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute.js";

import JuwelLayout from "layouts/Juwel/Juwel.js";
import KlyqaLayout from "layouts/Klyqa/Klyqa.js";
// import LoginScreen from "views/LoginScreen.js";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";
import LoginScreen from "views/LoginScreen";
import { AuthProvider } from "contexts/AuthContext";
import { Spinner } from "reactstrap";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <AuthProvider>
    <ThemeContextWrapper>
      <BackgroundColorWrapper>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<LoginScreen />} />
            <Route
              path="/juwel/*"
              element={
                <ProtectedRoute role="juwel">
                  <JuwelLayout />
                </ProtectedRoute>
              }
            />
            <Route
              path="/klyqa/*"
              element={
                <ProtectedRoute role="klyqa">
                  <KlyqaLayout />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </BrowserRouter>
      </BackgroundColorWrapper>
    </ThemeContextWrapper>
  </AuthProvider>
);
