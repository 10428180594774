// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style the login form container */
.login-form {
    background-color: #34495e;
    max-width: 300px;
    margin: 50px auto;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #ecf0f1;
}

/* Style the form elements */
.form-control {
    margin-bottom: 30px;
}

.form-control label {
    background: none;
    outline: none;
    margin-bottom: 10px;
}

.form-control input {
    width: 100%;
    padding: 8px;
    background: none;
    border: 1px solid #ecf0f1;
    outline: none;
    color: #ecf0f1;
}

/* Style the login button */
.button {
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #3498db;
    border: none;
    border-radius: 3px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #2980b9;
}

/* Add some padding to the container */
.container {
    padding: 50px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/loginForm.css"],"names":[],"mappings":"AAAA,mCAAmC;AACnC;IACI,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;IACxC,cAAc;AAClB;;AAEA,4BAA4B;AAC5B;IACI,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,cAAc;AAClB;;AAEA,2BAA2B;AAC3B;IACI,WAAW;IACX,aAAa;IACb,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA,sCAAsC;AACtC;IACI,aAAa;AACjB","sourcesContent":["/* Style the login form container */\n.login-form {\n    background-color: #34495e;\n    max-width: 300px;\n    margin: 50px auto;\n    padding: 20px;\n    border-radius: 5px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);\n    color: #ecf0f1;\n}\n\n/* Style the form elements */\n.form-control {\n    margin-bottom: 30px;\n}\n\n.form-control label {\n    background: none;\n    outline: none;\n    margin-bottom: 10px;\n}\n\n.form-control input {\n    width: 100%;\n    padding: 8px;\n    background: none;\n    border: 1px solid #ecf0f1;\n    outline: none;\n    color: #ecf0f1;\n}\n\n/* Style the login button */\n.button {\n    width: 100%;\n    padding: 10px;\n    margin-top: 20px;\n    background-color: #3498db;\n    border: none;\n    border-radius: 3px;\n    color: white;\n    font-size: 16px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n}\n\n.button:hover {\n    background-color: #2980b9;\n}\n\n/* Add some padding to the container */\n.container {\n    padding: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
