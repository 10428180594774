import React, { useState, useEffect } from 'react';
import "../assets/css/loginForm.css";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../contexts/AuthContext.js";

const LoginScreen = () => {
  const [localUsername, setLocalUsername] = useState('');
  const [localPassword, setLocalPassword] = useState('');
  const { user, login } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const path = user.role === 'juwel' ? '/juwel/dashboard' : '/klyqa/dashboard';
      navigate(path);
    }
  }, [user, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    login(localUsername, localPassword)
      .then((loginSuccessful) => {
        if (!loginSuccessful) {
          alert('Incorrect username or password!');
        }
        // useEffect will handle navigation if login is successful
      });
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit} className="login-form">
        <div className="form-control">
          <input
            type="text"
            placeholder="User Name"
            id="username"
            name="username"
            value={localUsername}
            onChange={(e) => setLocalUsername(e.target.value)}
          />
        </div>
        <div className="form-control">
          <input
            type="password"
            placeholder="Password"
            id="password"
            name="password"
            value={localPassword}
            onChange={(e) => setLocalPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="button">Login</button>
      </form>
    </div>
  );
};

export default LoginScreen;
