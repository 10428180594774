export function uuidv4() {
  /* eslint-disable no-bitwise */
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
export function createHeaders({ accountToken, requestId }) {
  if (accountToken) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${accountToken}`,
      "X-Request-Id": requestId ?? uuidv4(),
    };
  }

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Request-Id": requestId ?? uuidv4(),
  };
}

export const ApiEndpoint = {
  test: "https://app-api.test.app.juwel-aquarium.de",
  production: "https://app-api.app.juwel-aquarium.de",
};
